<template>
  <fm-modal title="常用语" v-model="modal" v-if="modal" width="800px">
    <div class="list" v-if="datas.length > 0">
      <div class="item" @click="choose(item.content)" v-for="(item, index) in datas" :key="index">
        <div class="content">
          {{item.content}}
        </div>
        <div class="action">
          <fm-btn size="mini" @click="update(item.content, index)">修改</fm-btn>
          <fm-btn size="mini" @click="del(index)">删除</fm-btn>
        </div>
      </div>
    </div>
    <div class="list" v-else>
      暂无常用语
    </div>
    <div class="fm-footer">
      <fm-btn style="margin-right: 5rem;" @click="add">新增</fm-btn>
      <fm-btn @click="modal = false">取消</fm-btn>
    </div>
  </fm-modal>

</template>

<script>
import {
  sysConfigRequest
} from '@/api'

export default {
  data () {
    return {
      loading: false,
      modal: false,
      datas: [],
      saving: false
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    userId () {
      return this.$store.getters.currentUserId
    }
  },
  methods: {
    choose (c) {
      this.$emit('choose', c)
      this.modal = false
    },
    async loadData () {
      if (!this.userId) {
        return
      }
      let datas = await sysConfigRequest.get({
        type: 'commonTerms',
        key: String(this.userId)
      })
      if (datas.length > 0) {
        this.datas = datas[0].value ? JSON.parse(datas[0].value) : []
      } else {
        this.datas = []
      }
    },
    async add () {
      const result = await this.$dialog.confirm({
        title: '新增常用语',
        promptValue: '',
        placeholder: '请输入',
        showInput: true,
        inputType: 'textarea'
      })
      if (result) {
        this.datas.push({
          content: result
        })
        this.save()
      }
    },
    async save () {
      await sysConfigRequest.add({
        type: 'commonTerms',
        configKey: this.userId,
        value: JSON.stringify(this.datas)
      })
      this.loadData()
    },
    async update (content, index) {
      const result = await this.$dialog.confirm({
        title: '新增常用语',
        promptValue: content,
        placeholder: '请输入',
        showInput: true,
        inputType: 'textarea'
      })
      if (result) {
        this.datas[index] = {
          content: result
        }
        this.save()
      }
    },
    del (index) {
      this.datas = this.datas.filter((v, i) => i !== index)
      this.save()
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  min-height: 10rem;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border-bottom: 1px solid #e8eaec;
    cursor: pointer;
    .content {
      padding-right: 20px;
      flex: 1;
    }
    .action {
      width: 130px;
      display: flex;
      align-items: center;
    }
  }
}
.doc-form {
  display: flex;
}
.left {
  flex: 1;
  margin-right: 2rem;
}
.right {
  flex: 1;
}
</style>